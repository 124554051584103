import {createSetTitleCallbackApp, sendCallback} from "../../../assets/js/app-callbacks";

class BuchhandlungUebersicht extends HTMLElement {

  connectedCallback() {
    this.setDataAttributes();
    this.setElements();
    this.bindEvents();
    this.initWeltbildText();
    this.initBuchhandlungen();
    if(this.sindAppCallbacksAktiv) {
      this.appCallbackSetTitle(this, this.dataset.appTitle);
    }
  }

  setDataAttributes() {
    this.loadUrl = this.dataset.loadUrl;
    this.nextPage = 0;
    this.searchValue = this.dataset.searchValue ? this.dataset.searchValue.trim() : null;
    this.selectedKoordinate = this.createKoordinate(this.dataset.breitengrad, this.dataset.laengengrad);
  }

  setElements() {
    this.weltbildContainer = document.querySelector('.weltbild-container');

    this.buchhandlungContainer = document.querySelector('.buchhandlung-container');
    this.buchhandlungLeerContainer = document.querySelector('.buchhandlung-container-leer');
    this.anzahlBuchhandlungElement = document.querySelector('.buchhandlung-anzahl');
    this.bezeichnungEinzahlElement = document.querySelector('.buchhandlung-bezeichnung-einzahl');
    this.bezeichnungMehrzahlElement = document.querySelector('.buchhandlung-bezeichnung-mehrzahl');

    this.filterForm = document.querySelector('.filter-form');
    this.searchInputElement = this.filterForm.querySelector("input");
    this.locationButtonElement = document.querySelector('.location-button');
    this.nachladenButton = document.querySelector('.button-weitere');
  }

  bindEvents() {
    if (this.nachladenButton) {
      this.nachladenButton.addEventListener('click', () => this.loadBuchhandlungen(false));
    }
    this.filterForm.addEventListener('submit', (event) => this.loadForSearch(event));
    this.locationButtonElement.addEventListener('click', () => this.loadForPosition());
  }

  initWeltbildText() {
    const referrerParam = new URLSearchParams(window.location.search).get('referrer');
    if (referrerParam === 'weltbild') {
      fetch('/include/cms/weltbild/weltbild-intro')
          .then(response => {
            if (!response.ok) {
              throw response;
            }
            return response.text();
          })
          .then(text => {
            this.weltbildContainer.innerHTML = text;
            this.changeVisibility(this.weltbildContainer, true);
          })
          .catch(error => console.error(error));
    }
  }

  initBuchhandlungen() {
    this.changeVisibility(this.nachladenButton, false);
    if (!navigator.geolocation) {
      this.disableLocationButton();
    }
    if (this.searchValue) {
      this.updateSearchField();
    }
    this.initView(this.buchhandlungContainer.querySelector("buchhandlung-liste"));
  }

  updateSearchField() {
    this.searchInputElement.value = this.searchValue;
  }

  disableLocationButton() {
    this.locationButtonElement.setAttribute("disabled", "disabled");
  }

  changeVisibility(element, visible) {
    if (visible) {
      element.removeAttribute('hidden');
    } else {
      element.setAttribute('hidden', true);
    }

  }

  get sindAppCallbacksAktiv() {
    return this.dataset.appCallbacksActive === 'true';
  }

  appCallbackSetTitle(parent, title) {
    sendCallback(createSetTitleCallbackApp('setTitle', title), parent);
  }

  loadBuchhandlungen(reset = false) {
    fetch(this.getBuchhandlungsseiteUrl())
      .then(response => {
        if (!response.ok) {
          throw response;
        }
        return response.text();
      })
      .then(text => this.onBuchhandlungsseiteReceived(text, reset))
      .catch(error => console.error(error));
  }

  getBuchhandlungsseiteUrl() {
    return `${this.loadUrl}${this.getParamString(this.nextPage, new URLSearchParams())}`;
  }

  onBuchhandlungsseiteReceived(text, reset) {
    const responseHtml = document.createElement("buchhandlung-seite");
    responseHtml.innerHTML = text;

    const pageElement = responseHtml.firstElementChild;
    this.initView(pageElement);

    if (reset) {
      this.buchhandlungContainer.innerHTML = '';
    }
    Array.from(pageElement.children).forEach(buchhandlung => this.buchhandlungContainer.appendChild(buchhandlung));
  }

  initView(pageElement) {
    const nextPage = pageElement.dataset.nextPage;
    const anzahlBuchhandlungen = pageElement.dataset.totalElements;
    const isMehrzahl = pageElement.dataset.isMehrzahl === "true";

    this.changeVisibility(this.buchhandlungLeerContainer, anzahlBuchhandlungen === "0");
    this.changeVisibility(this.buchhandlungContainer, anzahlBuchhandlungen !== "0");
    this.changeVisibility(this.bezeichnungEinzahlElement, !isMehrzahl);
    this.changeVisibility(this.bezeichnungMehrzahlElement, isMehrzahl);

    this.nextPage = nextPage ? nextPage : null;
    this.changeVisibility(this.nachladenButton, this.nextPage);
    this.anzahlBuchhandlungElement.innerHTML = `${anzahlBuchhandlungen}`;
    this.changePageUrl();
  }

  loadForSearch(event) {
    event.preventDefault();
    const formData = new FormData(this.filterForm);
    this.searchValue = formData.get("suche") ? formData.get("suche").trim() : null;
    this.selectedKoordinate = null;
    this.nextPage = null;
    this.loadBuchhandlungen(true);
  }

  loadForPosition() {
    if (!navigator.geolocation) {
      console.error("geolocation not supported")
    } else {
      navigator.geolocation.getCurrentPosition((position) => this.loadForGeoPosition(position), () => this.disableLocationButton());
    }
  }

  loadForGeoPosition(position) {
    this.searchValue = null;
    this.updateSearchField();
    this.selectedKoordinate = this.createKoordinate(position.coords.latitude, position.coords.longitude)
    this.loadBuchhandlungen(true);
  }

  createKoordinate(breitengrad, laengengrad) {
    if (!(breitengrad && laengengrad)) {
      return null;
    }
    return {
      "breitengrad": breitengrad,
      "laengengrad": laengengrad
    };
  }

  changePageUrl() {
    let existingParams = new URLSearchParams(location.search);

    window.history.pushState({}, null, this.getParamString(null, existingParams));
  }

  getParamString(page, existingParams) {
    let params = existingParams;

    if (this.searchValue) {
      params.set('suche', this.searchValue);
      params.delete("laengengrad");
      params.delete("breitengrad");
    }

    if (this.selectedKoordinate) {
      params.set('laengengrad', this.selectedKoordinate.laengengrad);
      params.set('breitengrad', this.selectedKoordinate.breitengrad);
      params.delete("suche");
    }

    if (page !== null) {
      params.set("page", page);
    } else {
      params.delete("page");
    }
    return params.size > 0 ? `?${params.toString()}`: '';
  }
}

if (!customElements.get("buchhandlung-uebersicht")) {
  customElements.define("buchhandlung-uebersicht", BuchhandlungUebersicht);
}
